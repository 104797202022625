import React from "react";
import Chats from "./Chats";
import Navbar from "./Navbar";
import Search from "./Search";

const Sidebar = ({ setSelectedChat }) => {
  return (
    <div className="sidebar">
      <Navbar />
      <Search />
      <Chats setSelectedChat={setSelectedChat} />
    </div>
  );
};

export default Sidebar;
