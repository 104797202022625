// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC82aGY7Gt_N4klNgwhhsVaBX3jnr7E9p4",
  authDomain: "chat-app-2a72c.firebaseapp.com",
  projectId: "chat-app-2a72c",
  storageBucket: "chat-app-2a72c.appspot.com",
  messagingSenderId: "606549898439",
  appId: "1:606549898439:web:88e747b0673dcad9fefae2",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
