import React from "react";
import { useState } from "react";
import Chat from "../components/Chat";
import Sidebar from "../components/Sidebar";

const Home = () => {
  const [selectedChat, setSelectedChat] = useState();

  return (
    <div className="home">
      <div className="container">
        <Sidebar setSelectedChat={setSelectedChat} />
        <Chat selectedChat={selectedChat} />
      </div>
    </div>
  );
};

export default Home;
