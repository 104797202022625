import axios from "axios";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db } from "../Firebase";
import { getAllMessagesRoute } from "../utils/APIRoutes";
import Message from "./Message";

const Messages = ({ selectedChat, currentUser, messages, setMessages }) => {
  const { data } = useContext(ChatContext);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.post(getAllMessagesRoute, {
        from: currentUser?._id,
        to: selectedChat?._id,
      });
      setMessages(response?.data);
    }

    // const unSub = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
    //   doc.exists() && setMessages(doc.data().messages);
    // });

    // return () => {
    //   unSub();
    // };
    // }, [data.chatId]);
    if (selectedChat) {
      fetchData();
    }
  }, [selectedChat?._id, currentUser?._id]);

  return (
    <div className="messages">
      {/* {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))} */}
      {messages === "response" ? (
        <p>No messages available</p>
      ) : (
        messages.map((m) => (
          <Message currentUser={currentUser} message={m} key={m.id} />
        ))
      )}
    </div>
  );
};

export default Messages;
