import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginRoute } from "../utils/APIRoutes";

function Login() {
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("chatAppToken")) {
      navigate("/");
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = e.target[0].value;
    const password = e.target[1].value;

    setValues({ ...values, email, password });

    const { data } = await axios.post(loginRoute, {
      email,
      password,
    });

    if (data.status === true) {
      localStorage.setItem("chatAppToken", JSON.stringify(data.user));
      navigate("/");
    } else {
      setError(true);
    }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <span className="logo">Chat App</span>
        <span className="title">Login</span>
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="email"></input>
          <input type="password" placeholder="password"></input>
          {error && <span>Something went wrong.</span>}
          <button>Sign in </button>
        </form>
        <p>
          You don't have an account? <Link to="/register">Register</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
