import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [currentUser, setCurrentUser] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    async function checkCurrentUser() {
      if (!localStorage.getItem("chatAppToken")) {
        navigate("/login");
        console.log("No token found");
      } else {
        setCurrentUser(await JSON.parse(localStorage.getItem("chatAppToken")));
        console.log(JSON.parse(localStorage.getItem("chatAppToken")));
      }
    }
    checkCurrentUser();
  }, []);

  console.log("currentUser", currentUser);
  return (
    <div className="navbar">
      <span className="logo">Chat App</span>
      <div className="user">
        {/* <img src={currentUser.photoURL} alt="" /> */}
        <span>{currentUser?.displayName}</span>
        <button
          onClick={() => {
            localStorage.removeItem("chatAppToken");
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Navbar;
