import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { v4 as uuidv4 } from "uuid";

const Message = ({ message, currentUser }) => {
  const { data } = useContext(ChatContext);

  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      key={uuidv4()}
      className={`message ${message.fromSelf && "owner"}`}
    >
      <div className="message-info">
        {/* <img
          src={
            message.senderId === currentUser._id
              ? currentUser.photoURL
              : data.user.photoURL
          }
          alt=""
        /> */}
        <span>Just now</span>
      </div>
      <div className="message-content">
        <p>{message.message}</p>
        {message.img && <img src={message.img} alt="" />}
      </div>
    </div>
  );
};

export default Message;
