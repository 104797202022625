import axios from "axios";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { db } from "../Firebase";
import { allUsersRoute } from "../utils/APIRoutes";

const Chats = ({ setSelectedChat }) => {
  const [chats, setChats] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();

  const handleSelect = (u) => {
    setSelectedChat(u);
  };

  useEffect(() => {
    async function checkCurrentUser() {
      if (!localStorage.getItem("chatAppToken")) {
        navigate("/login");
        console.log("No token found");
      } else {
        setCurrentUser(await JSON.parse(localStorage.getItem("chatAppToken")));
        console.log(JSON.parse(localStorage.getItem("chatAppToken")));
      }
    }
    checkCurrentUser();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (currentUser) {
        const data = await axios.get(`${allUsersRoute}/${currentUser._id}`);
        setChats(data.data);
        console.log("chats", chats);
      }
    }
    fetchData();
  }, [currentUser]);

  console.log("chats", chats);
  return (
    <div className="chats">
      {Object.entries(chats)
        ?.sort((a, b) => b[1].date - a[1].date)
        .map((chat) => (
          <div
            className="user-chat"
            key={chat[0]}
            onClick={() => handleSelect(chat[1])}
          >
            {/* <img src={chat[1].userInfo.photoURL} alt="" /> */}
            <div className="user-chat-info">
              <span>{chat[1].displayName}</span>
              {/* <p>{chat[1].lastMessage?.text}</p> */}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Chats;
