import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { registerRoute } from "../utils/APIRoutes";

function Register() {
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    displayName: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("chatAppToken")) {
      navigate("/home");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0];

    setValues({ ...values, displayName, email, password });

    const { data } = await axios.post(registerRoute, {
      displayName,
      email,
      password,
      file,
    });

    if (data.status === true) {
      localStorage.setItem("chatAppToken", JSON.stringify(data.user));
      navigate("/home");
    } else {
      setError(true);
    }

    // try {
    //   const res = await createUserWithEmailAndPassword(auth, email, password);

    //   const storageRef = ref(storage, displayName);

    //   const uploadTask = uploadBytesResumable(storageRef, file);

    //   uploadTask.on(
    //     (error) => {
    //       setError(true);
    //     },
    //     () => {
    //       getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //         await updateProfile(res.user, {
    //           displayName,
    //           photoURL: downloadURL,
    //         });
    //         await setDoc(doc(db, "users", res.user.uid), {
    //           uid: res.user.uid,
    //           displayName,
    //           email,
    //           photoURL: downloadURL,
    //         });

    //         await setDoc(doc(db, "userChats", res.user.uid), {});
    //         navigate("/");
    //       });
    //     }
    //   );
    // } catch (error) {
    //   setError(true);
    // }
  };

  return (
    <div className="form-container">
      <div className="form-wrapper">
        <span className="logo">Chat App</span>
        <span className="title">Register</span>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="display name"></input>
          <input type="email" placeholder="email"></input>
          <input type="password" placeholder="password"></input>
          <input style={{ display: "none" }} type="file" id="file"></input>
          <label htmlFor="file">
            {/* <img src="" alt="" />` */}
            <i class="fa-solid fa-upload"></i><span> Add an avatar</span>
          </label>
          <button>Sign up</button>
          {error && <span>Something went wrong.</span>}
        </form>
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
}

export default Register;
