import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../context/ChatContext";
import Input from "./Input";
import Messages from "./Messages";
import { host } from "../utils/APIRoutes";

import { io } from "socket.io-client";

export default function Chat({ selectedChat }) {
  const socket = useRef();
  const [currentUser, setCurrentUser] = useState();
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    async function checkCurrentUser() {
      if (!localStorage.getItem("chatAppToken")) {
        navigate("/login");
        console.log("No token found");
      } else {
        setCurrentUser(await JSON.parse(localStorage.getItem("chatAppToken")));
        console.log(JSON.parse(localStorage.getItem("chatAppToken")));
      }
    }
    checkCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      socket.current = io(host);
      socket.current.emit("add-user", currentUser._id);
    }
  }, [currentUser]);

  return (
    <div className="chat">
      <div className="chat-info">
        <span>{selectedChat?.displayName}</span>
      </div>
      <Messages
        currentUser={currentUser}
        selectedChat={selectedChat}
        socket={socket}
        messages={messages}
        setMessages={setMessages}
      />
      <Input
        currentUser={currentUser}
        selectedChat={selectedChat}
        socket={socket}
        messages={messages}
        setMessages={setMessages}
      />
    </div>
  );
}
