import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useContext, useRef, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { db, storage } from "../Firebase";
import { v4 as uuid } from "uuid";
import { AuthContext } from "../context/AuthContext";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import axios from "axios";
import { sendMessageRoute } from "../utils/APIRoutes";
import { useEffect } from "react";

const Input = ({
  currentUser,
  selectedChat,
  socket,
  setMessages,
  messages,
}) => {
  const [text, setText] = useState("");
  const [test, settest] = useState([]);

  const [img, setImg] = useState(null);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const ref = useRef();

  // const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const handleSend = async () => {
    await axios.post(sendMessageRoute, {
      from: currentUser?._id,
      to: selectedChat?._id,
      message: text,
    });

    socket.current.emit("send-msg", {
      to: selectedChat._id,
      from: currentUser._id,
      message: text,
    });

    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: text });
    setMessages(msgs);
    //   if (img) {
    //     const storageRef = ref(storage, uuid());
    //     const uploadTask = uploadBytesResumable(storageRef, img);
    //     uploadTask.on(
    //       (error) => {
    //         // setError(true);
    //       },
    //       () => {
    //         getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //           await updateDoc(doc(db, "chats", data.chatId), {
    //             messages: arrayUnion({
    //               id: uuid(),
    //               text,
    //               senderId: currentUser.uid,
    //               date: Timestamp.now(),
    //               img: downloadURL,
    //             }),
    //           });
    //         });
    //       }
    //     );
    //   } else {
    //     await updateDoc(doc(db, "chats", data.chatId), {
    //       messages: arrayUnion({
    //         id: uuid(),
    //         text,
    //         senderId: currentUser.uid,
    //         date: Timestamp.now(),
    //       }),
    //     });
    //   }
    //   await updateDoc(doc(db, "userChats", currentUser.uid), {
    //     [data.chatId + ".lastMessage"]: {
    //       text,
    //     },
    //     [data.chatId + ".date"]: serverTimestamp(),
    //   });
    //   await updateDoc(doc(db, "userChats", data.user.uid), {
    //     [data.chatId + ".lastMessage"]: {
    //       text,
    //     },
    //     [data.chatId + ".date"]: serverTimestamp(),
    //   });
    //   setText("");
    setText("");
  };

  useEffect(() => {
    console.log("socket.current", socket.current);
    console.log("socket", socket);
    if (socket.current) {
      console.log("ifici");
      socket.current.on("msg-receive", (text) => {
        console.log("test", { text });
        setArrivalMessage({ fromSelf: false, message: text });
      });
    }
  }, [socket.current]);

  useEffect(() => {
    console.log("Test");
  }, []);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const keyDown = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="input">
      <input
        type="input"
        placeholder="Type something.."
        onChange={(e) => setText(e.target.value)}
        value={text}
        onKeyDown={keyDown}
      ></input>
      <div className="send">
        <img src="" alt="" />
        <input
          type="file"
          style={{ display: "none" }}
          id="file"
          onChange={(e) => setImg(e.target.files[0])}
        />
        <label htmlFor="file">
          <i class="fa-solid fa-upload"></i>
        </label>
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Input;
